<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="row" style="display: flex; justify-content: space-between">
                    <div class="card col-lg-3">
                        <div class="card-body">
                            <div class="row">
                                <h4 class="card-title">Numbers of Sites : {{siteData.length !==0?siteData.length+passedsiteData.length+failedsiteData.length:passedsiteData.length+failedsiteData.length}}</h4>
                            </div>
                            <!-- Table -->
                        </div>
                    </div>
                    <div class="card col-lg-3">
                        <div class="card-body">
                            <div class="row">
                                <h4 class="card-title">Numbers of Passed Sites : {{passedsiteData ? passedsiteData.length:0}}</h4>
                            </div>
                            <!-- Table -->
                        </div>
                    </div>
                    <div class="card col-lg-3">
                        <div class="card-body">
                            <div class="row">
                                <h4 class="card-title">Numbers of Failed Sites : {{failedsiteData ? failedsiteData.length:0}}</h4>
                            </div>
                            <!-- Table -->
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Information</h4>
                        <br>
                        <div class="row form-group">
                            <!--<div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label> Operator Name :</label>
                                    <div class="mx-3 align-self-center h6"> {{ptData.ptround.operator_name}}</div>
                                </div>
                            </div>-->
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label> Session :</label>
                                    <div class="mx-3 align-self-center h6"> {{ptData.ptround.pt_session}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label>Month :</label>
                                    <div class="mx-3 align-self-center h6"> {{ptData.ptround.month}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label>Year :</label>
                                    <div class="mx-3 align-self-center h6"> {{ptData.ptround.year}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <div >State :</div>
                                    <div class="mx-3 align-self-center h6" v-if="siteData.length!==0">{{ptData.ptround.state}}</div>
                                    <div class="mx-3 align-self-center h6" v-else>COMPLETED</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label v-if="ptData.ptround.pt_type==='EID'">Result sample 1 :</label>
                                    <div class="mx-3 align-self-center h6" v-if="ptData.ptround.pt_type==='EID'">{{ptData.ptround.results_sample1 === 0 ? 'Undetected' : ptData.ptround.results_sample1 === 1 ? 'Detected' : ptData.ptround.results_sample1 === 2 ? 'Inavlid': ""}}</div>
                                    <!--<div class="mx-3 align-self-center h6" v-else> {{ptData.ptround.results_sample1}}</div>-->
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label v-if="ptData.ptround.pt_type==='EID'">Result sample 2 :</label>
                                    <div class="mx-3 align-self-center h6" v-if="ptData.ptround.pt_type==='EID'">{{ptData.ptround.results_sample2 === 0 ? 'Undetected' : ptData.ptround.results_sample2 === 1 ? 'Detected' : ptData.ptround.results_sample2 === 2 ? 'Inavlid': ""}}</div>
                                    <!--<div class="mx-3 align-self-center h6" v-else> {{ptData.ptround.results_sample2}}</div>-->
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <div v-if="ptData.ptround.pt_type==='EID'">Result sample 3 :</div>
                                    <div class="mx-3 align-self-center h6" v-if="ptData.ptround.pt_type==='EID'"> {{ptData.ptround.results_sample3 === 0 ? 'Undetected' : ptData.ptround.results_sample3 === 1 ? 'Detected' : ptData.ptround.results_sample3 === 2 ? 'Inavlid': ""}}</div>
                                    <!--<div class="mx-3 align-self-center h6" v-else> {{ptData.ptround.results_sample3}}</div>-->
                                </div>
                            </div>
                            <!--<div class="col-md-3" v-if="ptData.ptround.pt_type==='VL'">
                                <div class="d-inline-flex">
                                    <label>Result sample 4 :</label>
                                    <div class="mx-3 align-self-center h6"> {{ptData.ptround.results_sample4}}</div>
                                   
                                </div>
                            </div>
                            <div class="col-md-3" v-if="ptData.ptround.pt_type==='VL'">
                                <div class="d-inline-flex">
                                    <div >Result sample 5 :</div>
                                    <div class="mx-3 align-self-center h6"> {{ptData.ptround.results_sample5}}</div>
                                </div>
                            </div>-->
                        </div>
                        <button class="btn btn-primary" @click="edit()">Edit</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">Sites with No Test Done</h4>
                            </div>
                            <div class="col-md-10 text-right">
                                <b-button variant="success" v-b-modal.modal-standard>
                                    <i class="ri-add-fill align-middle mr-2"></i> Add Site
                                </b-button>
                            </div>
                            <b-modal
                                    id="modal-standard"
                                    ref="modal-standard"
                                    title="Add Site"
                                    title-class="font-18"
                                    hide-footer
                            >
                                <form @submit.prevent="addSiteToSession()">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label >Select Site</label>
                                            <multiselect v-model="value" label="site_name" :options="enrolledSite" :multiple="false" placeholder="Select Site"></multiselect>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary" type="submit">Add</button>
                                </form>
                            </b-modal>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="siteData"
                                    :fields="fields"
                                     responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                            >
                                <template v-slot:cell(actions)="{item}">
                                    <b-button class="actionBut" v-b-tooltip.hover title="Tracking Info" variant="info" @click.prevent="track(item)">
                                        <i class="ri-truck-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover title="Donwload"  @click.prevent="download(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="currentColor" d="m12 17l4-4l-1.4-1.4l-1.6 1.55V9h-2v4.15L9.4 11.6L8 13l4 4Zm-6 5q-.825 0-1.413-.588T4 20V8l6-6h8q.825 0 1.413.588T20 4v16q0 .825-.588 1.413T18 22H6Zm0-2h12V4h-7.15L6 8.85V20Zm0 0h12H6Z"/></svg>
                                    </b-button>

                                    <b-button class="actionBut" variant="danger" v-b-tooltip.hover title="Delete" v-b-modal.modal-delete @click.prevent="deleteF(item)">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <b-modal
                            id="modal-delete"
                            ref="modal-delete"
                            title="Delete Site"
                            hide-footer
                        >
                            <p class="row">Do you really want to delete this enrollment ?</p>
                            <div style="display: flex; justify-content: flex-end"> 
                                <b-button variant="primary" @click.prevent="hidemodal" class="mr-2">Cancel</b-button>
                                <b-button class="btn btn-danger" variant="danger" @click.prevent="deleteSiteToSession">Delete</b-button>
                            </div>
                        </b-modal>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">Passed Sites</h4>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="passedsiteData"
                                    :fields="fields"
                                     responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                            >
                                <template v-slot:cell(filename)="{item}">
                                    <b-button class="actionBut" v-if="item.filename" v-b-tooltip.hover v-b-modal.modal-preview title="Preview Image" variant="info" @click.prevent="preview(item)">
                                        <!-- <i class="ri-edit-fill align-middle "></i> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                        </svg>
                                    </b-button>
                                </template>
                                <template v-slot:cell(actions)="{item}">
                                    <b-button class="actionBut" v-b-tooltip.hover title="Edit Tracking Info" variant="info" @click.prevent="track(item)">
                                        <i class="ri-truck-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="success"   v-b-tooltip.hover title="Read Certificat" @click.prevent="print(item)">
                                        <i class="ri-printer-line align-middle" ></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover title="Read Result"  @click.prevent="download(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="currentColor" d="m12 17l4-4l-1.4-1.4l-1.6 1.55V9h-2v4.15L9.4 11.6L8 13l4 4Zm-6 5q-.825 0-1.413-.588T4 20V8l6-6h8q.825 0 1.413.588T20 4v16q0 .825-.588 1.413T18 22H6Zm0-2h12V4h-7.15L6 8.85V20Zm0 0h12H6Z"/></svg>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover v-b-modal.modal-corrective title="Corrective Action" variant="info" @click.prevent="deleteF(item)">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover v-b-modal.modal-passedtofail title="Passed Result to Fail" variant="warning" @click.prevent="deleteF(item)">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="danger" v-b-tooltip.hover title="Delete" v-b-modal.modal-delete @click.prevent="deleteF(item)">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <!-- <b-modal
                            id="modal-delete"
                            ref="modal-delete"
                            title="Delete Site"
                            hide-footer
                        >
                            <p class="row">Do you really want to delete this enrollment ?</p>
                            <div style="display: flex; justify-content: flex-end"> 
                                <b-button variant="primary" @click.prevent="hidemodal" class="mr-2">Cancel</b-button>
                                <b-button class="btn btn-danger" variant="danger" @click.prevent="deleteSiteToSession">Delete</b-button>
                            </div>
                        </b-modal> -->
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">Failed Sites</h4>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="failedsiteData"
                                    :fields="fields_failed"
                                     responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                            >
                                <template v-slot:cell(filename)="{item}">
                                    <b-button class="actionBut" v-if="item.filename" v-b-tooltip.hover v-b-modal.modal-preview title="Preview Image" variant="info" @click.prevent="preview(item)">
                                        <!-- <i class="ri-edit-fill align-middle "></i> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                        </svg>
                                    </b-button>
                                </template>
                                <template v-slot:cell(actions)="{item}">
                                    <b-button class="actionBut" v-b-tooltip.hover title="Edit Tracking Info" variant="info" @click.prevent="track(item)">
                                        <i class="ri-truck-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover title="Read Result"  @click.prevent="download(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="currentColor" d="m12 17l4-4l-1.4-1.4l-1.6 1.55V9h-2v4.15L9.4 11.6L8 13l4 4Zm-6 5q-.825 0-1.413-.588T4 20V8l6-6h8q.825 0 1.413.588T20 4v16q0 .825-.588 1.413T18 22H6Zm0-2h12V4h-7.15L6 8.85V20Zm0 0h12H6Z"/></svg>
                                    </b-button>
                                    <b-button v-if="item.reason_to_fail" class="actionBut" v-b-tooltip.hover v-b-modal.modal-passedtofail variant="info" title="View Fail Reason"  @click.prevent="deleteF(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" id="eye">
                                            <g data-name="Layer 2"><path color="white" fill="white" d="M12,5C7.68,5,4,8.33,1.94,11.45L1.59,12l.36.55C4,15.67,7.68,19,12,19s8-3.33,10.06-6.45l.36-.55-.36-.55C20,8.33,16.32,5,12,5Zm0,12c-3.28,0-6.21-2.49-8-5,1.8-2.51,4.73-5,8-5s6.21,2.49,8,5C18.21,14.51,15.28,17,12,17Z"></path><path fill="white" d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"></path></g>
                                        </svg>
                                        <!--<svg class="svg-icon" viewBox="0 0 20 20" width="15" height="15" id="eye">
                                            <path fill="none" d="M19.471,8.934L18.883,8.34c-2.096-2.14-4.707-4.804-8.903-4.804c-4.171,0-6.959,2.83-8.996,4.897L0.488,8.934c-0.307,0.307-0.307,0.803,0,1.109l0.401,0.403c2.052,2.072,4.862,4.909,9.091,4.909c4.25,0,6.88-2.666,8.988-4.807l0.503-0.506C19.778,9.737,19.778,9.241,19.471,8.934z M9.98,13.787c-3.493,0-5.804-2.254-7.833-4.3C4.182,7.424,6.493,5.105,9.98,5.105c3.536,0,5.792,2.301,7.784,4.332l0.049,0.051C15.818,11.511,13.551,13.787,9.98,13.787z"></path>
                                            <circle fill="none" cx="9.98" cy="9.446" r="1.629"></circle>
                                        </svg>-->
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover v-b-modal.modal-corrective title="Corrective Action" variant="success" @click.prevent="deleteF(item)">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="danger" v-b-tooltip.hover title="Delete" v-b-modal.modal-delete @click.prevent="deleteF(item)">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <b-modal
                            id="modal-corrective"
                            ref="modal-corrective"
                            title="Corrective Action"
                            hide-footer
                            size="lg"
                        >
                            <div class="form-group col-12" style="display: flex; align-items: center; gap: 10px;">
                                <!-- <label>Corrective Action</label> -->
                                <input
                                    v-if="deleteVal"
                                    type="text"
                                    v-model="corrective[0]"
                                    placeholder="Corrective Action"
                                    class="form-control"
                                />
                                <b-button @click.prevent="add">Add</b-button>
                            </div>
                            <div class="form-group col-12" v-if="inc>0">
                                <input
                                    v-if="deleteVal"
                                    type="text"
                                    v-model="corrective[1]"
                                    placeholder="Corrective Action"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group col-12" v-if="inc>1">
                                <input
                                    v-if="deleteVal"
                                    type="text"
                                    v-model="corrective[2]"
                                    placeholder="Corrective Action"
                                    class="form-control"
                                />
                            </div>
                            <div style="display: flex; justify-content: flex-end"> 
                                <b-button variant="primary" @click.prevent="hidemodalaction" class="mr-2">Cancel</b-button>
                                <b-button class="btn btn-success" variant="success" @click.prevent="saveCorrective">Save</b-button>
                            </div>
                        </b-modal>
                        <b-modal
                            id="modal-passedtofail"
                            ref="modal-passedtofail"
                            title="Reason"
                            hide-footer
                            size="lg"
                        >
                            <div class="form-group col-12" style="display: flex; align-items: center; gap: 10px;">
                                <!-- <label>Corrective Action</label> -->
                                <textarea
                                    v-if="deleteVal"
                                    type="text"
                                    v-model="deleteVal.reason_to_fail"
                                    placeholder="Reason"
                                    class="form-control"
                                />
                            </div>
                            <div style="display: flex; justify-content: flex-end">
                                <b-button variant="primary" @click.prevent="$refs['modal-passedtofail'].hide()" class="mr-2">Cancel</b-button>
                                <b-button class="btn btn-success" variant="success" @click.prevent="passedtoFail">Save</b-button>
                            </div>
                        </b-modal>
                        <b-modal
                            id="modal-preview"
                            ref="modal-preview"
                            title="Preview Image"
                            hide-footer
                            size="lg"
                        >
                            <div class="form-group col-12" style="display: flex; align-items: center; gap: 10px;">
                                <img v-if="deleteVal" :src="baseurl+'/uploads/'+deleteVal.filename" width="100%"/>
                            </div>
                            <div style="display: flex; justify-content: flex-end"> 
                                <b-button variant="primary" @click.prevent="hidepreviewmodalaction">Close</b-button>
                            </div>
                        </b-modal>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>

        <div style="display: flex; justify-content: space-around" v-show="false" v-if="selectedSite && downloadFile">
            <vue-html2pdf
                    :show-layout="false"
                    :float-layout="false"
                    :paginate-elements-by-height="800"
                    :enable-download="true"
                    :preview-modal="false"
                    :filename="selectedSite.site_name"
                    :htmlToPdfOptions="{
                        margin: [10, 10],
                        filename: selectedSite.site_name
                    }"
                    :pdf-quality="2"
                    :manual-pagination="true"
                    :margin="[50, 50]"
                    pdf-content-width="700px"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    ref="html2Pdf"

                    @progress="onProgress($event)"
                    @startPagination="startPagination()"
                    @hasPaginated="hasPaginated()"
                    @beforeDownload="beforeDownload($event)"
                    @hasDownloaded="hasDownloaded($event)"
            >
                <div class="body" slot="pdf-content">
                    <div class="container">
                        <div class="container_header">
                            <h5><b>Point-of-Care Technology Continuous Quality Improvement
                                <br>For Early Infant Diagnosis Program
                            </b></h5>
                            <h5>{{country.country_name}} ({{country.country_prefix}})</h5>
                        </div>
                        <div class="thead-1" style="border: 1px solid; border-collapse: collapse">
                            <h6 style="padding: 5px">Participant Result Summary (Résumé des résultats du site) – Panel {{ptData.ptround.year}}-B</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="border-right: 1px solid; padding: 5px; "><h6>Site Name</h6></div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Date PT Received</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">{{downloadFile.details[selectedSite.id].receieved}}</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="padding: 5px; border-right: 1px solid;"><h6>{{selectedSite.site_name}}</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Date PT Results Reported</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">{{downloadFile.details[selectedSite.id].reported}}</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Site ID</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Instrument</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Reagent Lot No:</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">{{downloadFile.details[selectedSite.id].lotno}}</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;">{{selectedSite.ptid}}</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; ">{{downloadFile.details[selectedSite.id].instrument}}</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Expiry Date</h6></div>
                                <div class="col-lg-3">{{downloadFile.details[selectedSite.id].expiry}}</div>
                            </div>
                        </div>
                        <div class="thead-2">
                            <h6>Site Result Summary/ résultat du site</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around;">
                                <div class="col-lg-3" style="color: white"><h6></h6>Expected Result</div>
                                <div class="col-lg-2" style="border-left: 1px solid;border-right: 1px solid;border-top: 1px solid; padding: 5px;"><h6>{{ptData.ptround.year}} B1</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>{{ptData.ptround.year}} B2</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>{{ptData.ptround.year}} B3</h6></div>
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid; border-top: 1px solid;"><h6>Score</h6></div>
                            </div>
                            <div style="display: flex; flex-direction: column; text-align: center; justify-content: space-around; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex; text-align: center; justify-content: space-around;">
                                    <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;"><h6>Expected Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">{{downloadFile.details[selectedSite.id].er1 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].er1 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].er1 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">{{downloadFile.details[selectedSite.id].er2 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].er2 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].er2 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">{{downloadFile.details[selectedSite.id].er3 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].er3 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].er3 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6>{{downloadFile.details[selectedSite.id].perc}} %</h6></div>
                                </div>
                                <div style="display: flex; text-align: center; justify-content: space-around; border-collapse: collapse">
                                    <div class="col-lg-3" style="border-right: 1px solid;border-top: 1px solid; "><h6>Your Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">{{downloadFile.details[selectedSite.id].r1 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].r1 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].r1 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">{{downloadFile.details[selectedSite.id].r2 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].r2 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].r2 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">{{downloadFile.details[selectedSite.id].r3 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].r3 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].r3 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-3" style="color: white">Reagent Lot No:</div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-3">
                            <h6>Summary of All Site Scores/ Résumé des résultats de tous les sites</h6>
                            <div style="display: flex; flex-direction: column; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex;">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Participating Sites</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring below 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6><b>Percentage of Sites Scoring 100%</b></h6></div>
                                </div>
                                <div  style="display: flex; border-top: 1px solid">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>{{downloadFile.sites_qui_participe_a_cette_session}}</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>{{percent100.length}}</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>{{percentLess100.length}}</h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6>{{Math.floor((percent100.length/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</h6></div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-4">
                            <h6>Percentage of all Participating Sites reporting correctly</h6>
                            <h6>Pourcentage de tous les sites participants qui établissent correctement leurs rapports</h6>
                            <div style="display: flex">
                                <div class="col-lg-3" style="color: white">Correctly Reported</div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid; border-left: 1px solid;border-right: 1px solid;"><h6><b>{{ptData.ptround.year}} B1</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>{{ptData.ptround.year}} B2</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>{{ptData.ptround.year}} B3</b></h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>Average</b></h6></div>
                            </div>
                            <div style="display: flex; border: 1px solid;">
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid;"><b>Correctly Reported</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>{{Math.floor((res.e1/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>{{Math.floor((res.e2/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>{{Math.floor((res.e3/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</b></div>
                                <div class="col-lg-3" style="padding: 5px;"> <b>{{Math.floor(((res.e1/downloadFile.sites_qui_participe_a_cette_session)*100+(res.e1/downloadFile.sites_qui_participe_a_cette_session)*100+(res.e1/downloadFile.sites_qui_participe_a_cette_session)*100)/3)}} %</b></div>
                            </div>
                        </div>
                        <div class="thead-5">
                            <h6>OPERATOR’S NAME : </h6>
                            <h6><b> {{downloadFile.details[selectedSite.id].operator_name}}</b></h6>
                        </div>

                    </div>
                </div>
            </vue-html2pdf>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import {BASEURL} from "../../../helpers/helper";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import VueHtml2pdf from 'vue-html2pdf'
    import {axios_delete, axios_get, axios_post} from "../../../helpers/helper";

    /**
     * Form validation component
     */
    export default {
        page: {
            title: "View PT",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,PageHeader ,Multiselect, VueHtml2pdf},
        data() {
            return {
                title: "View PT",
                user: null,
                country: JSON.parse(localStorage.getItem('user')).country,
                inc:0,
                corrective: [null, null, null],
                passedsiteData: null,
                failedsiteData: null,
                res: {e1: 0, e2: 0, e3: 0},
                loader: false,
                baseurl: BASEURL,
                items: [
                    {
                        text: "View all PT Rounds",
                        href: "/ptround/eid-ptrounds"
                    },
                    {
                        text: "PT Round datails",
                        active: true
                    }
                ],
                htmlToPdfOptions: {
                    margin: [10, 10],
                    filename: this.selectedSite ? this.selectedSite.site_name: null,
                },
                ptData:[],
                siteData:null,
                downloadFile:null,
                percent100:[],
                percentLess100:[],
                selectedSite:null,
                selectedId:null,
                enrolledSite:null,
                deleteVal:null,
                value:"",
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: false,
                sortBy:"id",
                fields: [
                    { key: "ptid", sortable: true },
                    { key: "site_name", sortable: true },
                    { key: "email", sortable: true },
                    { key: "region", sortable: true },
                    { key: "location", sortable: true },
                    { key: "enrollment_status", sortable: true },
                    { key: "result", sortable: true },
                    { key: "telephone", sortable: false },
                    { key: "filename", sortable: true },
                    { key: "actions", sortable: false },
                ],
                fields_failed: [
                    { key: "ptid", sortable: true },
                    { key: "site_name", sortable: true },
                    { key: "email", sortable: true },
                    { key: "region", sortable: true },
                    /* { key: "location", sortable: true }, */
                    { key: "enrollment_status", sortable: true, label: 'Status' },
                    { key: "result", sortable: true },
                    { key: "filename", sortable: true },
                    /* { key: "telephone", sortable: false }, */
                    /* { key: "site_category", sortable: true }, */
                    /* { key: "corrective_measures", sortable: true, label: 'Corrective Action' }, */
                    { key: "actions", sortable: false },
                ],
            };

        },
        validations: {
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                console.log(this.siteData)
                return this.siteData.length;
            }
        },
        methods: {
            download(e){
                this.$router.push({
                    path:'/pt-round/review/',
                    query:{site_id: e.id, pt_round: this.$route.params.id, year: this.ptData.ptround.year},
                    component: () => import('./review')
                })
                //window.location.href = '/pt-round/review/'+this.$route.params.id+'/'+this.ptData.ptround.year+'/'+e.id
                /*this.selectedSite = e;
                console.log(this.selectedSite)
                this.percent100=[]
                this.percentLess100=[]
                axios_get('/exportsitedata/'+e.id+'/'+this.$route.params.id+'?year='+this.ptData.ptround.year,{}, (d)=>{
                    console.log(d)
                    this.downloadFile = d
                    console.log(this.downloadFile)
                    Object.keys(d.details).map((e, i)=>{
                        if (d.details[e].perc === 100){
                            this.percent100.push(d.details[e])
                        }else{
                            this.percentLess100.push(d.details[e])
                        }
                        if (d.details[e].er1 === d.details[e].r1){
                            this.res.e1 = this.res.e1+1;
                        }
                        if (d.details[e].er2 === d.details[e].r2){
                            this.res.e2 = this.res.e2+1;
                        }
                        if (d.details[e].er3 === d.details[e].r3){
                            this.res.e3 = this.res.e3+1;
                        }
                        console.log(i)
                        console.log(this.percent100)
                        console.log(this.percentLess100)
                    })
                    setTimeout(()=>{
                        this.$refs['html2Pdf'].generatePdf()
                    }, 1000)
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('finale', f)
                    this.loader = false
                })*/
                //this.$refs['html2Pdf'].generatePdf()
            },

            async beforeDownload ({ html2pdf, options=this.htmlToPdfOptions, pdfContent }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    console.log(pdf)
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        //pdf.setPage(i)
                        //pdf.setFontSize(10)
                        //pdf.setTextColor(150)
                        //pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    }
                }).save()
            },
            add(){
                if(this.inc <3){
                    this.inc= this.inc + 1;
                }
                console.log(this.inc)
            },
            hidemodal(){
                this.$refs['modal-delete'].hide()
            },
            hidemodalaction(){
                this.$refs['modal-corrective'].hide()
            },
            hidepreviewmodalaction(){
                this.deleteVal = null
                this.$refs['modal-preview'].hide()
            },
            preview(e){
                console.log(BASEURL)
                this.deleteVal = e
            },
            edit(){
                window.location.href = "/pt-round/edit/"+this.$route.params.id
            },
            track(e){
                console.log(e)
                window.location.href = "/pt-round/tracking-info/"+e.enrollment_id
            },
            deleteF(e){
                console.log(e)
                this.deleteVal = e
                if(e.corrective_measures !== null){
                    this.corrective = JSON.parse(e.corrective_measures)
                    this.inc = this.corrective.length
                    console.log(this.corrective)
                    console.log(this.inc)
                }
            },

            passedtoFail(){
                axios_post('/update-reason-to-fail', {reason_to_fail: this.deleteVal.reason_to_fail, enrollmentId: this.deleteVal.enrollment_id}, (d)=>{
                    console.log(d)
                    window.location.reload()
                }, (e)=>{
                    console.log(e)
                }, ()=>{
                    console.log("finally")
                })
            },

            print(e){
                console.log(e)
                window.location.href = "/pt-round/certification/"+e.enrollment_id
            },

            saveCorrective(){
                this.loader = true
                this.$refs['modal-corrective'].hide()
                console.log(this.corrective)
                axios_post('/update-corrective-measures', {'text': JSON.stringify(this.corrective), 'enrollment_id': this.deleteVal.enrollment_id}, (d)=>{
                    console.log(d.corrective_measures)
                    this.corrective = JSON.parse(d.corrective_measures)
                    console.log(this.corrective)
                    window.location.reload()
                }, (e)=>{
                    console.log('error', e)
                }, ()=>{
                    console.log('finale')
                    this.loader = false
                })
            },

            addSiteToSession(){
                console.log(this.value)
                this.loader = true
                this.$refs['modal-standard'].hide()
                axios_post('/enrollment', {'site_id': this.value.id, 'pt_round_id': parseInt(this.$route.params.id)}, (d)=>{
                    console.log('success', d)
                    axios_get("/pt_rounds/"+this.id,{},(da)=>{
                        console.log(da);
                        console.log(this.ptData);
                        this.ptData=da;
                        this.siteData=da.sites
                        console.log(this.ptData.ptround);
                        console.log(this.ptData.sites);
                        this.siteData=this.siteData.filter(e=>{return e.result=="NO TEST DONE"})
                        this.siteData=this.siteData.map(e=>{e.region=e.region.name; e.enrollment_status= new Date(e.enrollment_status).getFullYear();return e;})
                        console.log(this.siteData)    
                    },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        }
                    );
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('finale', f)
                    this.loader = false
                })
            },
            deleteSiteToSession(){
                this.loader = true
                this.$refs['modal-delete'].hide()
                console.log(this.deleteVal)
                axios_delete('/enrollment/'+this.deleteVal.enrollment_id, {}, (da)=>{
                    console.log('success', da)
                    axios_get("/pt_rounds/"+this.id,{},(da)=>{
                        console.log(da);
                        console.log(this.ptData);
                        this.ptData=da;
                        this.siteData=da.sites
                        console.log(this.ptData.ptround);
                        console.log(this.ptData.sites);
                        //this.siteData=this.ptData.sites.filter(e=>{return e.region.country_id==this.user.country_id})
                        this.siteData=this.siteData.map(e=>{e.region=e.region.name; e.enrollment_status= new Date(e.enrollment_status).getFullYear();return e;})
                        console.log(this.siteData)    
                    },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        }
                    );                  
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('final', f)
                    this.loader = false
                })
            }


        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            this.user = JSON.parse(localStorage.getItem('user'))
                axios_get("/pt_rounds/"+this.id,{},(da)=>{
                        console.log(da);
                        console.log(this.ptData);
                        this.ptData=da;
                        console.log(this.ptData.ptround);
                        console.log(this.ptData.sites);
                        this.siteData=this.ptData.sites.filter(e=>{return e.region.country_id==this.user.country_id})
                        this.siteData=this.ptData.sites.map(e=>{e.enrollment_status= new Date(e.enrollment_status).getFullYear(); e.region=e.region.name;return e;})
                        this.passedsiteData=this.siteData.filter(e=>{return e.result== "PASSED"})
                        this.failedsiteData=this.siteData.filter(e=>{return e.result== "FAILED"})
                        this.siteData=this.siteData.filter(e=>{return e.result== "NO TEST DONE"})

                        console.log(this.failedsiteData)
                    
                    axios_get("/sites",{},(d)=>{
                        console.log(da)
                        console.log(d)
                        this.enrolledSite=d.filter(e=>{return e.region.country_id==this.user.country_id && e.site_category === da.ptround.pt_type})
                        if(this.ptData.sites.length>0){
                            for(var i=0; i<this.ptData.sites.length; i++){
                                this.enrolledSite=this.enrolledSite.filter(e=>{return e.id !==this.ptData.sites[i].id})
                            }
                            
                        }
                        
                        this.enrolledSite=this.enrolledSite.map(e=>{e.region=e.region.name; return e})
                        console.log(this.enrolledSite)
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        }
                    );

                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );
            },
    };
</script>


<style scoped>
    .actionBut{
        margin-left:15px;
    }
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }

    .container_header{
        text-align: center;
    }
    tbody, thead{
        width: 500px;
    }
    tr{
        text-align: center;
        font-size: 18px;
        margin: 1rem;
    }
    .thead-1,.thead-2,.thead-3,.thead-4{
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
    }
    .thead-5{
        display: flex;
        position: absolute;
        right: 5rem;
        bottom: 0;
        margin-top: 5rem;
     }
</style>